<template>
  <div class="clear">
    <b-ace-editor :readonly="disabled" :snippets="false" :value="value" :height="height" @input="$emit('input', $event)" lang="json"> </b-ace-editor>
  </div>
</template>
<script>
import Vue from 'vue';
import Editor from 'bin-ace-editor';
require('brace/ext/language_tools');
require('brace/mode/json');
require('brace/snippets/json');
require('brace/theme/chrome');
Vue.component(Editor.name, Editor);
export default {
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '200'
    }
  },
  data() {
    return {
      showError: false
    };
  },
  methods: {
    async formatSql() {
      let res = {};
      if (res) {
        this.$emit('input', res.payload);
      }
    },
    onClose() {
      this.showError = false;
    },
    run() {
      this.$emit('run');
    }
  }
};
</script>
<style lang="less">
.clear {
  clear: both;
}
</style>
