export const menu = [
  { name: 'mock', key: '/mock' }
  // NEW ITEM HERE
];

export const menuRouter = menu.map(v => {
  return {
    path: v.key,
    meta: {
      title: v.name,
      breadCrumb: [{ name: 'APP' }, { name: v.name }]
    },
    component: () => import(`@/views${v.key}${v.key}.vue`)
  };
});
