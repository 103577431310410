<template>
  <div class="mgr20">
    <a-select placeholder="请选择" style="width:100px" v-model="radioDate">
      <a-select-option value="recent">最近七天</a-select-option>
      <a-select-option value="today">今天</a-select-option>
      <a-select-option value="week">本周</a-select-option>
      <a-select-option value="month">本月</a-select-option>
      <a-select-option value="year">本年</a-select-option>
      <a-select-option value="custom">自定义</a-select-option>
    </a-select>
    <a-range-picker v-if="radioDate === 'custom'" style="width:225px" :placeholder="['开始日期', '结束日期']" valueFormat="YYYY-MM-DD" />
  </div>
</template>
<script>
import { getRange, transRange } from '@/libs/time';
export default {
  model: {
    prop: 'date',
    event: 'change'
  },
  props: {
    tip: {
      type: String
    },
    date: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: '本周',
      radioDate: 'week'
    };
  },
  created() {
    // this.chooseRadio(this.date);
  },
  watch: {},
  computed: {},
  methods: {}
};
</script>
<style lang="less"></style>
