<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <a-spin :spinning="$store.state.LOADING">
        <router-view />
      </a-spin>
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>
<style lang="less">
@import './styles/antd.less';
#app > .ant-spin-nested-loading {
  height: 100vh;
}
</style>
