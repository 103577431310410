




















































/**
 * 演示示例
 * <k-table-card-slot
      title="数据问题列表"
      :loading="loading"
      :columns.sync="columns"
      :data-source="dataSource"
      :total="total"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :scroll="{ x: 2000 }"
      @refresh="getList"
    />
 */
import { Column } from '@/types/columns';
import { Item } from '@/types/item';
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class VueList extends Vue {
  @Prop() title!: string;
  @PropSync('columns') syncColumns!: Column[];
  // 数据
  @Prop() dataSource!: any[];
  // 加载动画
  @Prop({ default: false }) loading!: boolean;
  // 是否开启多选
  @Prop({ default: null }) rowSelection!: boolean | object;
  // 设置横向或纵向滚动
  @Prop({ default: undefined }) scroll!: undefined | object;

  tableSize: string = 'default';
  isFullScreen: boolean = false;
  colOptions: Item[] = [];
  columnsCache: Column[] = [];
  cols: any[] = [];

  onChange(values: string[]) {
    let opts: Column[] = [];
    this.columnsCache.forEach(v => {
      let r = values.find(val => v.dataIndex === val);
      r && opts.push(v);
    });
    this.syncColumns = opts;
  }

  created() {
    this.initCols();
  }

  initCols() {
    let columns = this.syncColumns;
    this.columnsCache = JSON.parse(JSON.stringify(columns));
    this.cols = [];
    this.colOptions = this.syncColumns.map((v: Column) => {
      this.cols.push(v.dataIndex);
      return { label: v.title, value: v.dataIndex };
    });
  }

  setTableSize(size: string) {
    this.tableSize = size;
  }

  refresh() {
    this.$emit('refresh', {
      page: this.page,
      pageSize: this.pageSize
    });
  }

  /**
   * 分页
   */
  page: number = 1;
  pageSize: number = 10;
  @Prop({ default: 100 }) total!: number;
  pageSizeOptions: string[] = ['10', '20', '50', '100'];

  @Watch('page')
  onPageChange = () => this.refresh();
  @Watch('pageSize')
  onpageSizeChange = () => this.refresh();

  onShowSizeChange(current: number, size: number) {
    this.pageSize = size;
  }

  showTotal = (total: number) => `共${total}条记录`;

  initPage() {
    this.page = 1;
    this.pageSize = 10;
  }
}
