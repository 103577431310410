import { render, staticRenderFns } from "./k-mini-card.vue?vue&type=template&id=3def8188&scoped=true&"
import script from "./k-mini-card.vue?vue&type=script&lang=ts&"
export * from "./k-mini-card.vue?vue&type=script&lang=ts&"
import style0 from "./k-mini-card.vue?vue&type=style&index=0&id=3def8188&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3def8188",
  null
  
)

export default component.exports