<template>
  <a-switch :size="size" :disabled="disabled" v-model="checkedValue" checked-children="是" un-checked-children="否" />
</template>
<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Number | String,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    }
  },
  created() {
    // this.$emit('change', 0);
  },
  computed: {
    checkedValue: {
      get() {
        return this.checked == 0;
      },
      set(value) {
        this.$emit('change', value ? 0 : 1);
      }
    }
  }
};
</script>
<style lang="less">
.k-tag-input {
  .ant-tag {
    padding: 4px 10px 5px;
  }
}
</style>
