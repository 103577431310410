





import { sleep } from '@/libs/util';
import { Vue, Component, ModelSync } from 'vue-property-decorator';
@Component
export default class KTag extends Vue {
  @ModelSync('tag', 'change', { type: String })
  tags!: string;

  get tagArr(): string[] {
    return this.tags ? this.tags.split(',') : [];
  }

  set tagArr(value: string[]) {
    this.$emit('change', value.join(','));
  }

  /**
   * 回车切分标签
   */
  async onInputKeydown(e: any) {
    if (e.code === 'Enter') {
      let dom: any = this.$refs.kTagInput;
      dom.blur();
      await sleep(250);
      dom.focus();
    }
  }

  separators: string[] = [';'];
}
