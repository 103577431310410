import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Antd from 'ant-design-vue';
import globalComponents from '@/components';
import 'ant-design-vue/dist/antd.css';

Vue.use(Antd);
Vue.use(globalComponents);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
