




































import { Column } from '@/types/columns';
import { Item } from '@/types/item';
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class VueList extends Vue {
  @Prop() title!: string;
  @PropSync('columns') syncColumns!: Column[];
  @PropSync('tableSize') syncTableSize!: string;

  isFullScreen: boolean = false;
  colOptions: Item[] = [];
  columnsCache: Column[] = [];
  cols: any[] = [];

  onChange(values: string[]) {
    let opts: Column[] = [];
    this.columnsCache.forEach(v => {
      let r = values.find(val => v.dataIndex === val);
      r && opts.push(v);
    });
    this.syncColumns = opts;
  }

  created() {
    this.initCols();
  }

  initCols() {
    let columns = this.syncColumns;
    this.columnsCache = JSON.parse(JSON.stringify(columns));
    this.cols = [];
    this.colOptions = this.syncColumns.map((v: Column) => {
      this.cols.push(v.dataIndex);
      return { label: v.title, value: v.dataIndex };
    });
  }

  setTableSize(size: string) {
    this.syncTableSize = size;
  }

  refresh() {
    this.$emit('refresh');
  }

  add() {
    this.$emit('add');
  }

  setColumns() {}

  fullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }
}
