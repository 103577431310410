export const exportBlobFile = (filename: string, data: any) => {
  let blob = new Blob([data], { type: 'application/octet-stream' });
  if ('download' in document.createElement('a')) {
    let ele = document.createElement('a');
    ele.download = filename;
    ele.style.display = 'none';
    ele.href = URL.createObjectURL(blob);
    document.body.appendChild(ele);
    ele.click();
    document.body.removeChild(ele);
  } else {
    window.navigator.msSaveBlob(blob, filename);
  }
};

export const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time));
};

export const checkCode = (code: string = '') => {
  const reg = /^\w+$/;
  return reg.test(code);
};

export const checkEmpty = (str: string): boolean => {
  return str === '' || str === null || str === undefined;
};

export const checkCron = (cron: string = '') => {
  const reg = /^(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*|\?) ?){5,7}$/;
  return reg.test(cron);
};

// 深拷贝
export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));
