






import { Vue, Component } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class KFormFullscreen extends Vue {
  isFullScreen: boolean = false;

  fullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }
}
