import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { Component } from 'vue-property-decorator';
import { menuRouter } from '@/config/menu';

Vue.use(VueRouter);

const originalPush: any = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: string): Promise<Route> {
  return originalPush.call(this, location).catch((err: any) => err);
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/mock',
    component: () => import('../views/main.vue'),
    children: menuRouter
  },
  {
    path: '/schedule',
    component: () => import('../views/schedule/schedule.vue')
  }
];

const router = new VueRouter({
  routes
});

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

router.beforeEach((to, from, next) => {
  window.document.title = to.meta?.title || 'IDEAX-TOOLS';
  next();
});

export default router;
