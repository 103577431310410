





import { Column } from '@/types/columns';
import { Item } from '@/types/item';
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class VueList extends Vue {
  @Prop() status!: string;
  @Prop() text!: string;
  
}
