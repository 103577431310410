














































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { FilterItem } from '@/types/filter-items';
@Component
export default class KFilter extends Vue {
  @Prop() title!: string;
  @Prop() items!: FilterItem[];
  
  @Watch('items')
  onItemsChange() {
    this.computedWidth();
  }

  filter = {};

  visible: boolean = false;

  created() {
    this.computedWidth();
  }

  width: number = 66;
  maxValueWidth: number = 300;
  computedWidth() {
    let maxLength: number = 0;
    this.items.forEach(v => {
      maxLength = maxLength < v.label.length ? v.label.length : maxLength;
    });
    this.width = maxLength * 14;
    let ref: any = this.$refs.itWidth;
    let children = ref ? ref.getElementsByClassName('item') : [];
    if (children.length > 0) {
      let itemWidth = children[0].offsetWidth;
      this.maxValueWidth = itemWidth - this.width - 20;
    }
  }

  excute() {
    this.$emit('excute', this.filter);
  }

  reset() {
    this.filter = {};
    this.$emit('reset');
  }

  filterOption(input: string, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
}
