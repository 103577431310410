


















import { Vue, Component, Prop } from 'vue-property-decorator';
@Component
export default class KMiniCard extends Vue {
  @Prop() config!: any;
}
