





import { Vue, Component, ModelSync, Prop } from 'vue-property-decorator';
@Component
export default class KTagInfo extends Vue {
  @Prop() tags!: string;

  get tagsArr() {
    return this.tags ? this.tags.split(',') : [];
  }
}
