






import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class VueList extends Vue {
  @Prop() tip!: string;
}
