const requireComponent = require.context('./common', false, /k\-(.*)\.vue$/);

export default {
  install(Vue: { component: (arg0: string, arg1: any) => void }) {
    requireComponent.keys().forEach((fileName: string) => {
      const component = requireComponent(fileName);
      const componentName: string = (fileName.split('/').pop() || '').replace('.vue', '');
      Vue.component(componentName, component.default || component);
    });
  }
};
